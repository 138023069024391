import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav, Container } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useConfig } from '../config';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();
  const config = useConfig();

  // Erstelle sortierte Liste der aktivierten Menüpunkte
  const menuItems = config?.menu ? 
    Object.entries(config.menu)
      .filter(([_, item]) => item.enabled)
      .sort((a, b) => a[1].order - b[1].order)
      .map(([key, item]) => ({
        path: key === 'home' ? '/' : `/${key}`,
        title: item.title
      }))
    : [];

  return (
    <BootstrapNavbar expand="lg" className="navbar">
      <Container>
        <BootstrapNavbar.Brand as={Link} to="/" className="d-lg-none"></BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav">
          <GiHamburgerMenu size={24} className="burger-icon" />
        </BootstrapNavbar.Toggle>
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {menuItems.map(item => (
              <Nav.Link
                key={item.path}
                as={Link}
                to={item.path}
                className={location.pathname === item.path ? 'active' : ''}
              >
                {item.title}
              </Nav.Link>
            ))}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;
