import React from 'react';
import { Container, Card } from 'react-bootstrap';

const textStyle = {
  textAlign: 'left',
  maxWidth: '800px',
  margin: '0 auto',
  paddingLeft: '40px'
};

const Datenschutz = () => {
  return (
    <Container className="my-4">
      <h2 className="mb-4 text-center">Datenschutzerklärung</h2>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">1. Datenschutz auf einen Blick</h3>
          
          <h4 className="mb-3 text-center">Allgemeine Hinweise</h4>
          <p style={textStyle}>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten 
            passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie 
            persönlich identifiziert werden können.
          </p>

          <h4 className="mb-3 text-center">Datenerfassung auf dieser Website</h4>
          <h5 className="mb-2 text-center">Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h5>
          <p style={textStyle}>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber:<br />
            Turn und Sportverein Jägersfreude 1888 e.V.<br />
            Hauptstraße 94<br />
            66123 Saarbrücken
          </p>

          <h5 className="mb-2 text-center">Wie erfassen wir Ihre Daten?</h5>
          <p style={textStyle}>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich 
            z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
          </p>
          <p style={textStyle}>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere 
            IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem 
            oder Uhrzeit des Seitenaufrufs).
          </p>

          <h5 className="mb-2 text-center">Wofür nutzen wir Ihre Daten?</h5>
          <p style={textStyle}>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. 
            Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
          </p>
        </Card.Body>
      </Card>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">2. Hosting</h3>
          <p style={textStyle}>
            Unser Hoster erhebt in sog. Logfiles folgende Daten, die Ihr Browser übermittelt:
          </p>
          <ul className="list-unstyled" style={textStyle}>
            <li className="mb-2">• IP-Adresse</li>
            <li className="mb-2">• Adresse der vorher besuchten Website (Referer)</li>
            <li className="mb-2">• Datum und Uhrzeit der Anfrage</li>
            <li className="mb-2">• Zeitzonendifferenz zur GMT</li>
            <li className="mb-2">• Inhalt der Anforderung</li>
            <li className="mb-2">• HTTP-Statuscode</li>
            <li className="mb-2">• übertragene Datenmenge</li>
            <li className="mb-2">• Website, von der die Anforderung kommt</li>
            <li className="mb-2">• Browsertyp und Browserversion</li>
            <li className="mb-2">• Betriebssystem</li>
          </ul>
          <p style={textStyle}>
            Die Logfiles enthalten IP-Adressen und andere Daten, die eine Zuordnung ermöglichen. Dies ist 
            erforderlich, um die Website vor Angriffen zu schützen und eine reibungslose Nutzung der Website 
            zu gewährleisten.
          </p>
          <p style={textStyle}>
            Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes 
            Interesse liegt in der technisch fehlerfreien Darstellung und der Optimierung unserer Website.
          </p>
        </Card.Body>
      </Card>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">3. Contentful CMS</h3>
          <p style={textStyle}>
            Wir nutzen das Content Management System Contentful zur Verwaltung unserer Website-Inhalte. 
            Contentful verarbeitet dabei keine personenbezogenen Besucherdaten. Die Inhalte werden über 
            eine sichere Verbindung (SSL/TLS) abgerufen.
          </p>
        </Card.Body>
      </Card>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">4. Kontaktaufnahme per E-Mail</h3>
          <p style={textStyle}>
            Wenn Sie per E-Mail mit uns in Kontakt treten, werden Ihre angegebenen Daten (E-Mail-Adresse, ggf. Name und weitere von Ihnen übermittelte Informationen) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
          </p>
          <p style={textStyle}>
            Die Datenverarbeitung zum Zweck der Kontaktaufnahme erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung bzw. nach Art. 6 Abs. 1 S. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist.
          </p>
        </Card.Body>
      </Card>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">5. Ihre Rechte</h3>
          <p style={textStyle}>Sie haben folgende Rechte:</p>
          <ul className="list-unstyled" style={textStyle}>
            <li className="mb-2">• Recht auf Auskunft (Art. 15 DSGVO)</li>
            <li className="mb-2">• Recht auf Berichtigung (Art. 16 DSGVO)</li>
            <li className="mb-2">• Recht auf Löschung (Art. 17 DSGVO)</li>
            <li className="mb-2">• Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
            <li className="mb-2">• Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
            <li className="mb-2">• Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DSGVO)</li>
            <li className="mb-2">• Recht auf Beschwerde bei einer Aufsichtsbehörde (Art. 77 DSGVO)</li>
          </ul>
        </Card.Body>
      </Card>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">6. SSL- bzw. TLS-Verschlüsselung</h3>
          <p style={textStyle}>
            Diese Seite nutzt aus Sicherheitsgründen eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte 
            Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" 
            wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>
        </Card.Body>
      </Card>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">7. Änderungen der Datenschutzerklärung</h3>
          <p style={textStyle}>
            Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen 
            rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der 
            Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services.
          </p>
          <p style={textStyle}>
            Stand: April 2025
          </p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Datenschutz;
