import React from 'react';
import { Container, Card } from 'react-bootstrap';

const textStyle = {
  textAlign: 'left',
  maxWidth: '800px',
  margin: '0 auto',
  paddingLeft: '40px'
};

const Legal = () => {
  return (
    <Container className="my-4">
      <h2 className="legal-heading mb-4 text-center">Rechtliche Hinweise</h2>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">Haftungsausschluss für die technische Implementierung</h3>
          <p style={textStyle}>
            Die technische Implementierung (Programmierung) dieser Website wurde von Swen Engel als 
            freundschaftlicher Dienst ohne jegliche Vergütung erstellt. Für rechtliche Belange und 
            Anfragen ist ausschließlich der Verein unter den im Impressum genannten Kontaktdaten zu kontaktieren.
            Der Entwickler:
          </p>
          <ul className="list-unstyled" style={textStyle}>
            <li className="mb-2">• Hat ausschließlich den Programmcode erstellt</li>
            <li className="mb-2">• Hat keinen Einfluss auf die vom Verein veröffentlichten Inhalte</li>
            <li className="mb-2">• Betreibt oder hostet die Website nicht</li>
            <li className="mb-2">• Verwaltet keine technischen Dienste oder Infrastruktur</li>
            <li className="mb-2">• Übernimmt keinerlei Gewährleistung für die Funktionsfähigkeit der Website</li>
            <li className="mb-2">• Übernimmt keine Verantwortung für die Richtigkeit der Inhalte</li>
            <li className="mb-2">• Haftet für keinerlei Schäden durch die Nutzung der Website</li>
            <li className="mb-2">• Lehnt jegliche Haftung für technische Probleme ab</li>
            <li className="mb-2">• Übernimmt keine Garantie für die Verfügbarkeit der Website</li>
          </ul>
          <p style={textStyle}>
            Die Nutzung der Website und ihrer Funktionen erfolgt auf eigenes Risiko. Jegliche Haftung 
            des Entwicklers wird ausgeschlossen, soweit dies gesetzlich zulässig ist.
          </p>
        </Card.Body>
      </Card>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">Urheberrecht am Programmcode</h3>
          <p style={textStyle}>
            Der gesamte Quellcode dieser Website (einschließlich HTML, CSS, JavaScript und React-Komponenten) 
            ist urheberrechtlich geschütztes geistiges Eigentum von Swen Engel. Jegliche Form der Nutzung, 
            Vervielfältigung, Bearbeitung, Verbreitung oder sonstige Verwertung des Codes - auch in Teilen - 
            bedarf der ausdrücklichen schriftlichen Genehmigung durch Swen Engel. Dies gilt insbesondere für:
          </p>
          <ul className="list-unstyled" style={textStyle}>
            <li className="mb-2">• Den gesamten Quellcode der Website</li>
            <li className="mb-2">• Die Programmstruktur und Architektur</li>
            <li className="mb-2">• Individuelle Programmroutinen und Funktionen</li>
            <li className="mb-2">• Selbst entwickelte Module und Komponenten</li>
            <li className="mb-2">• Design-Implementierungen und Layout-Strukturen</li>
          </ul>
          <p style={textStyle}>
            Diese Beschränkungen gelten nicht für die verwendeten Open-Source-Komponenten, 
            die unter ihren jeweiligen Lizenzen genutzt werden.
          </p>
        </Card.Body>
      </Card>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">Verwendete technische Komponenten und Lizenzen</h3>
          <ul className="list-unstyled" style={textStyle}>
            <li className="mb-2">• React (MIT-Lizenz)</li>
            <li className="mb-2">• React Router (MIT-Lizenz)</li>
            <li className="mb-2">• React Bootstrap (MIT-Lizenz)</li>
            <li className="mb-2">• React Icons (MIT-Lizenz)</li>
            <li className="mb-2">• Bootstrap (MIT-Lizenz)</li>
            <li className="mb-2">• Contentful JavaScript SDK (MIT-Lizenz)</li>
            <li className="mb-2">• @contentful/rich-text-react-renderer (MIT-Lizenz)</li>
            <li className="mb-2">• EmailJS (MIT-Lizenz)</li>
          </ul>
          <p style={textStyle}>
            Die MIT-Lizenz erlaubt die freie Verwendung, Modifikation und Verteilung der Software, 
            solange der Lizenztext und der Copyright-Hinweis in allen Kopien oder wesentlichen 
            Teilen der Software enthalten sind.
          </p>
        </Card.Body>
      </Card>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">Technische Dienste</h3>
          <p style={textStyle}>
            Sämtliche technische Dienste wie Hosting (Strato) und Content Management System (Contentful) 
            laufen über den Verein. Die redaktionelle Verantwortung und rechtliche Haftung für alle 
            veröffentlichten Inhalte liegt ausschließlich beim Turn und Sportverein Jägersfreude 1888 e.V.
          </p>
        </Card.Body>
      </Card>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 text-center">Änderungsvorbehalt</h3>
          <p style={textStyle}>
            Wir behalten uns vor, diese rechtlichen Hinweise jederzeit und ohne Nennung von Gründen zu ändern. 
            Die jeweils aktuelle Version ist auf dieser Website abrufbar.
          </p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Legal;
