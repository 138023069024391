import React, { useEffect } from 'react';
import client from '../config/contentful';

const KalenderTest = () => {
  useEffect(() => {
    const fetchKalender = async () => {
      try {
        // Hole alle Kalender-Einträge
        const kalenderResponse = await client.getEntries({
          content_type: 'kalender'
        });
        
        console.log('Gefundene Kalender:', kalenderResponse.items.map(item => ({
          id: item.sys.id,
          name: item.fields.kalendername,
          farbe: item.fields.kalenderfarbe
        })));

        // Hole alle Veranstaltungen
        const eventsResponse = await client.getEntries({
          content_type: 'veranstaltungen'
        });

        console.log('Gefundene Veranstaltungen:', eventsResponse.items.map(item => ({
          id: item.sys.id,
          titel: item.fields.titel,
          start: item.fields.startDatum,
          kalender: item.fields.kalender?.fields?.kalendername || 'Kein Kalender zugewiesen'
        })));

      } catch (error) {
        console.error('Fehler beim Laden:', error);
      }
    };

    fetchKalender();
  }, []);

  return (
    <div>
      <p>Bitte schauen Sie in die Browser-Konsole (F12)</p>
    </div>
  );
};

export default KalenderTest;
