import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Container, Card, Alert, Row, Col } from 'react-bootstrap';
import de from 'date-fns/locale/de';
import client from '../config/contentful';

const locales = {
  'de': de,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const messages = {
  allDay: 'Ganztägig',
  previous: 'Zurück',
  next: 'Vor',
  today: 'Heute',
  month: 'Monat',
  week: 'Woche',
  day: 'Tag',
  agenda: 'Agenda',
  date: 'Datum',
  time: 'Uhrzeit',
  event: 'Veranstaltung',
  noEventsInRange: 'Keine Veranstaltungen in diesem Zeitraum.',
};

const Kalender = () => {
  const [events, setEvents] = useState([]);
  const [kalenderTypen, setKalenderTypen] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Lade zuerst die Kalendertypen
        const kalenderResponse = await client.getEntries({
          content_type: 'kalender'
        });

        const kalenderListe = kalenderResponse.items.map(item => ({
          id: item.sys.id,
          name: item.fields.kalendername,
          farbe: item.fields.kalenderfarbe?.replace('##', '#') || '#6c757d'
        }));

        setKalenderTypen(kalenderListe);

        // Dann lade die Veranstaltungen
        const response = await client.getEntries({
          content_type: 'veranstaltungen'
        });

        const calendarEvents = response.items.map(item => {
          const cleanHexColor = (color) => {
            if (!color) return '#6c757d';
            return color.replace('##', '#');
          };

          // Hole den Kalendernamen, wenn ein Kalender zugewiesen ist
          const kalenderName = item.fields.kalender?.fields?.kalendername;

          return {
            title: item.fields.titel || 'Ohne Titel',
            start: new Date(item.fields.startDatum),
            end: item.fields.endDatum ? new Date(item.fields.endDatum) : new Date(item.fields.startDatum),
            allDay: item.fields.ganztagig || false,
            description: item.fields.beschreibung || '',
            location: item.fields.ort || '',
            color: cleanHexColor(item.fields.farbe),
            // Zeige den Kalender nur an, wenn einer zugewiesen ist
            kalenderName: kalenderName || ''
          };
        });

        setEvents(calendarEvents);
      } catch (error) {
        setError('Die Veranstaltungen konnten nicht geladen werden. Bitte versuchen Sie es später erneut.');
      }
    };

    fetchData();
  }, []);

  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: event.color,
        borderRadius: '5px',
        opacity: 0.8,
        color: isLightColor(event.color) ? '#000000' : '#ffffff',
        border: 'none',
        display: 'block'
      }
    };
  };

  const isLightColor = (color) => {
    if (!color) return false;
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return brightness > 155;
  };

  return (
    <Container className="my-4">
      <h2 className="mb-4 text-center">Veranstaltungskalender</h2>
      {error && (
        <Alert variant="danger" className="mb-4">
          {error}
        </Alert>
      )}
      <Card className="shadow-sm mb-4">
        <Card.Body>
          {kalenderTypen.length > 0 && (
            <div className="mb-3">
              <Row className="align-items-center">
                <Col xs="auto">
                  <strong>Legende:</strong>
                </Col>
                {kalenderTypen.map((kalender) => (
                  <Col xs="auto" key={kalender.id}>
                    <span 
                      className="badge me-2" 
                      style={{ 
                        backgroundColor: kalender.farbe,
                        color: isLightColor(kalender.farbe) ? '#000000' : '#ffffff'
                      }}
                    >
                      {kalender.name}
                    </span>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          <div style={{ height: '500px' }}>
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              culture='de'
              messages={messages}
              views={['month', 'week', 'day', 'agenda']}
              defaultView='month'
              eventPropGetter={eventStyleGetter}
              tooltipAccessor={event => {
                // Für ganztägige Events keine Zeit anzeigen
                if (event.allDay) {
                  return `Ganztägig${event.location ? `\nOrt: ${event.location}` : ''}${event.description ? `\n${event.description}` : ''}${event.kalenderName ? `\nKalender: ${event.kalenderName}` : ''}`;
                }

                // Für Events mit Uhrzeit nur die Uhrzeit anzeigen
                const formatTime = (date) => {
                  return format(date, 'HH:mm', { locale: de });
                };

                const zeitraum = `${formatTime(event.start)} - ${formatTime(event.end || event.start)} Uhr`;
                return `${zeitraum}${event.location ? `\nOrt: ${event.location}` : ''}${event.description ? `\n${event.description}` : ''}${event.kalenderName ? `\nKalender: ${event.kalenderName}` : ''}`;
              }}
              style={{ height: '100%' }}
              min={new Date(2023, 1, 1, 7, 0)} // 7:00 Uhr
              max={new Date(2023, 1, 1, 22, 0)} // 22:00 Uhr
              // Scrolle automatisch zur aktuellen Uhrzeit in Tages- und Wochenansicht
              scrollToTime={new Date(2023, 1, 1, 7, 0)}
            />
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Kalender;
